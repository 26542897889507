@import 'theme/bootstrap-config';
@import 'bootstrap/scss/bootstrap-grid';
@import 'theme/colors';
@import 'theme/material';
@import "primeicons/primeicons.css";
@import "theme/primesng-custom";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.app-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*background: url('assets/background.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;*/
  background-color: snow;
  z-index: -1;
}

.container.page-container {
  margin-top: 32px;
  margin-bottom: 32px;

  > .row > * {
    margin-top: $grid-gutter-width;
  }
}

.mat-card {

  mat-card-title {
    .back-button {
      margin-left: -14px;
      top: -4px;

      mat-icon {
        margin-left: -15px;
        font-size: 40px;
      }
    }
  }

  .card-top-right {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .table-wrapper {
    margin-left: -16px;
    margin-right: -16px;

    &:first-child {
      margin-top: -16px;
    }

    &:last-child {
      margin-bottom: -16px;
    }

    table {
      width: 100%;
    }
  }
}

.mat-dialog-actions {
  justify-content: flex-end;
}


h1 {
  margin-left: 5%;
  font-family: 'Open Sans', verdana, arial, sans-serif;
}

app-waterfall {
  plotly-plot {
    .svg-container {
      min-height: 376px;

      .gl-container {
        > div {
          pointer-events: none !important;
        }
      }
    }
  }
}

app-azimuth-correlogram {
  plotly-plot {
    .svg-container {
      min-height: 395px;

      .gl-container {
        > div {
          pointer-events: none !important;
        }
      }
    }
  }
}

lightbox {
  gallery-item {
    pointer-events: none;
  }
}
